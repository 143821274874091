import React, { useState }  from "react"
import axios from "axios"
import PropTypes from 'prop-types'
import { Box, Label, Input, Textarea, Button, Message } from 'theme-ui'

const ContactForm = () => {
    
    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
      }
    };
    const handleSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/08c858d8-5344-4d7c-add3-ac1c591319e4",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thank you for contacting us. We'll get back to you soon!", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };
    
  return (
    <form onSubmit={handleSubmit}>
      <Box variant='forms.row'>
        <Label htmlFor='contact-form-name'>Name</Label>
        <Input type='text' id='contact-form-name' name='name' required />
      </Box>
      <Box variant='forms.row'>
        <Label htmlFor='contact-form-email'>Email</Label>
        <Input
            type='email'
            placeholder='email@example.com'
            id='contact-form-email'
            name='email'
            required
         />
      </Box>
      <Box variant='forms.row'>
        <Label htmlFor='contact-form-subject'>Subject</Label>
        <Input type='text' id='contact-form-subject' name='subject' required />
      </Box>
      <Box variant='forms.row'>
        <Label htmlFor='contact-form-message'>Your Message</Label>
        <Textarea name='message' id='contact-form-message' />
      </Box>
      <Box variant='forms.row'>
        <div class="g-recaptcha" data-sitekey="6LeZLb4ZAAAAANRBp_q7IsiH9bqTWAsdwGAciknI"></div>
      </Box>
      <Button
        disabled={serverState.submitting}
        type='submit'
        required
      >
        Submit
      </Button>
      {serverState.status && (
         <p className={!serverState.status.ok ? "errorMsg" : ""}>
          <Message variant='success'>{serverState.status.msg}</Message>
        </p>
      )}
    </form>
  )
}

export default ContactForm

ContactForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool
}