import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Our Commitment' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        If you have any questions about the items, prices, shipping or how to shop etc,
        please do not hesitate to contact us.
      </Text>
    </Card>
  </Section>
)

export default Commitment